/**
* Created by tudou on 2020/4/25 10:52.
*/
<template>
  <div class="login">
    <div class="login-con">
      <a-card icon="log-in" title="卡丁车综合管理系统" :bordered="false">
        <div class="form-con">
          <a-form-model :model="form" @submit="handleSubmit" @submit.native.prevent>
            <a-form-model-item>
              <a-input v-model="form.storeId" placeholder="请输入商户ID">
                <a-icon slot="prefix" type="key" style="color:rgba(0,0,0,.25)"></a-icon>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.username" placeholder="请输入用户名">
                <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)"></a-icon>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.passwd" type="password" placeholder="请输入密码">
                <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)"></a-icon>
              </a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-form-item
                :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }"
              >
                <a-input v-model="form.code" placeholder="请输入验证码">
                </a-input>
              </a-form-item>
              <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }"></span>
              <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
                <img class="imgCode" :src="imgCode" alt="" width="100%" @click="getCaptchaCode">
              </a-form-item>
            </a-form-model-item>
            <a-form-model-item>
              <a-button
                type="primary"
                html-type="submit"
                block>
                登录
              </a-button>
            </a-form-model-item>
          </a-form-model>
          <p class="login-tip">如忘记密码，请联系管理处理</p>
        </div>
      </a-card>
    </div>

    <a-modal v-model="notChrome" title="浏览器版本低" :closable="false" :footer="null" width="650px">
      <div class="low-browser">
        <div class="low-title"><strong>您的浏览器需要更新才能访问!</strong></div>
        <div class="low-subtitle">请选择对应平台进行下载</div>
        <div class="browser-list">
          <a href="https://pan.baidu.com/s/1HM2Qk93-NBEKB2S1W7Ew_A" target="_blank">
            <img src="../../assets/images/Chrome.png" alt="Chrome">
            <span>Chrome浏览器</span>
          </a>
          <!--<a href="//www.google.cn/chrome/" target="_blank">-->
            <!--<img src="../../assets/images/Safari.png"-->
                 <!--alt="Chrome">-->
            <!--<span>Mac</span>-->
          <!--</a>-->
        </div>

        <p>提取码: <strong>dwnf</strong></p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { captchaCode, login } from '@/api/user';
import {
  isEmpty, setCookie, setLocal, getLocal
} from '@/libs/utils';
import { tokenKey } from '@/libs/config';
import { storeInfo } from '../../api/system';

export default {
  name: 'Login',
  components: {},
  props: {},
  data() {
    return {
      url: '/',
      notChrome: false,
      form: {
        storeId: '',
        username: '',
        passwd: '',
        code: '',
      },
      imgCode: '',
      nowTimestamp: Date.now(),
    };
  },
  computed: {},
  watch: {},
  created() {
    const ua = navigator.userAgent.toLowerCase();
    this.notChrome = !ua.includes('chrome');
    this.getCaptchaCode();

    const lastUser = getLocal('lastUser');
    if (lastUser) {
      this.form.storeId = lastUser.storeId;
      this.form.username = lastUser.username;
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getCaptchaCode() {
      this.nowTimestamp = Date.now();
      // this.imgCode = window.location.host + baseURL + '/api/captcha/code?key=' + this.nowTimestamp;
      const params = {
        key: this.nowTimestamp,
      };
      captchaCode(params).then((data) => {
        this.imgCode = `data:image/jpg;base64,${data}`;
      });
    },
    handleSubmit() {
      this.url = '/';
      if (isEmpty(this.form.username)) {
        this.$message.error('请输入用户名');
        return;
      }

      if (isEmpty(this.form.passwd)) {
        this.$message.error('请输入密码');
        return;
      }

      if (isEmpty(this.form.code)) {
        this.$message.error('请输入验证码');
        return;
      }
      this.$store.commit('setUserInfo', '');
      this.$store.commit('setStoreInfo', '');
      this.$store.commit('setSubPermissionList', '');
      this.form.codeKey = this.nowTimestamp;
      login(this.form).then((data) => {
        const lastUser = {
          storeId: this.form.storeId,
          username: this.form.username
        };
        setLocal('lastUser', lastUser);
        const { token } = data;
        if (data.userDTO.remark && data.userDTO.remark.startsWith('/')) {
          console.log('登录');
          this.url = data.userDTO.remark;
        }
        console.log(data.permissionList);
        this.$store.commit('setSubPermissionList', data.permissionList);
        this.$store.commit('setUserInfo', data);
        setCookie(tokenKey, token);
        this.getStoreInfo();
        // window.location.reload(true);
      }).catch(() => {
        // 出错之后重新加载验证码
        this.getCaptchaCode();
      });
    },
    getStoreInfo() {
      const that = this;
      storeInfo().then((data) => {
        that.$store.commit('setStoreInfo', data);
        that.$router.replace(this.url);
        // setLocal('storeInfo', data);
      });
    }
  },
};
</script>

<style lang="less" scoped>
  .login {
    width: 100%;
    height: 100%;
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/login.jpg');
      background-size: cover;
      background-position: center;
      filter: blur(2px);
    }

    &-con {
      position: absolute;
      right: 160px;
      top: 50%;
      transform: translateY(-60%);
      width: 300px;
      z-index: 2;

      &-header {
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        padding: 30px 0;
      }

      .form-con {
        padding: 10px 0 0;
      }

      .login-tip {
        font-size: 10px;
        text-align: center;
        /*color: #c3c3c3;*/
      }
    }
  }

  .imgCode {
    cursor: pointer;
  }

  .low-browser {
    color: #666;
    font-family: arial;

    .low-title {
      color: #000000;
      font-size: 24px;
    }
  }

  .browser-list {
    padding: 20px 0;
    letter-spacing: 2px;

    a {
      display: inline-block;
      margin: 20px;
    }

    span {
      display: inline-block;
      margin-left: 8px;
    }
  }

  .browser-list img {
    width: 100px;
  }

</style>
